import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Query } from '../../interfaces/query';
import { ProcedimientoAdministrativo } from '../../interfaces/procedimiento-administrativo';
import { environment } from 'src/environments/environment';
import { LoginService } from '../login/login.service';

const url = environment.url;
const urlDynamo = environment.dynamoUrl;
@Injectable({
    providedIn: 'root',
})
export class ProcedimientoAdminsitrativoService {
    constructor(private http: HttpClient, private loginService: LoginService) {}

    async getProcedimientos(query: Query) {
        return new Promise((resolve) => {
            this.http.post(`${url}/gateway/api/v1/indexer/kerno/search`, query).subscribe((resp: any) => {
                resolve(resp.data);
            });
        });
    }

    async getProcedimientosDynamo(): Promise<ProcedimientoAdministrativo[]> {
        const siu_job_code =
            this.loginService.getUsuarioActual().jobCode?.startsWith('DEV_') ||
            this.loginService.getUsuarioActual().jobCode?.startsWith('PRE_')
                ? this.loginService.getUsuarioActual().jobCode?.replace(/^(DEV_|PRE_)/, '')
                : this.loginService.getUsuarioActual().jobCode;

        const query = {
            appId: environment.appId,
            userToken: '',
            appKey: environment.appKey,
            procedimiento: environment.procedimiento,
            tipo_identificador: 'puesto.siu.siu_job_code',
            identificador: siu_job_code,
            atributos: [
                'puesto.gestiona_procedimiento.signatura_procedimiento',
                'puesto.gestiona_procedimiento.fecha_inicio_procedimiento',
                'puesto.gestiona_procedimiento.fecha_fin_procedimiento',
            ],
        };
        return new Promise((resolve) => {
            // if (localStorage.getItem('procedimientosAdministrativos')) {
            //     resolve(JSON.parse(localStorage.getItem('procedimientosAdministrativos')!));
            //     return;
            // }
            this.http.post(`${urlDynamo}/api/data/v1/obtener_entidad`, query).subscribe(async (resp: any) => {
                const procedimientosList: ProcedimientoAdministrativo[] = [];
                if (!resp.body.atributos.puesto?.gestiona_procedimiento) {
                    resolve(procedimientosList);
                    return;
                }
                let procedimientos: any[] = Object.values(resp.body.atributos.puesto?.gestiona_procedimiento);
                procedimientos = procedimientos.filter((procedimiento: any) => {
                    const fechaInicio = new Date(
                        procedimiento['puesto.gestiona_procedimiento.fecha_inicio_procedimiento']
                    ).getTime();
                    const fechaFin = procedimiento['puesto.gestiona_procedimiento.fecha_fin_procedimiento']
                        ? new Date(procedimiento['puesto.gestiona_procedimiento.fecha_fin_procedimiento']).getTime()
                        : null;
                    return fechaFin
                        ? fechaInicio <= Date.now() && fechaFin >= new Date().getTime()
                        : fechaInicio <= Date.now();
                });
                if (!procedimientos.length) resolve(procedimientosList);
                for (const procedimiento of procedimientos) {
                    const datosProcedimiento = await this.getDatosProcedimiento(
                        procedimiento['puesto.gestiona_procedimiento.signatura_procedimiento']
                    );
                    // if (!datosProcedimiento['es_servicio']) {
                    //     continue;
                    // }
                    const organismoProductor = datosProcedimiento['tiene_organismo_productor']
                        ? datosProcedimiento['tiene_organismo_productor'][
                              Object.keys(datosProcedimiento['tiene_organismo_productor'])[0]
                          ]
                        : null;
                    procedimientosList.push({
                        nombre: datosProcedimiento['procedimiento.nombre'],
                        nombreOrganismoProductor: organismoProductor
                            ? organismoProductor['procedimiento.tiene_organismo_productor.codigo_organismo_productor'] +
                              ' - ' +
                              organismoProductor['procedimiento.tiene_organismo_productor.nombre_organismo_productor']
                            : 'Cualquiera',
                        codigo: procedimiento['puesto.gestiona_procedimiento.signatura_procedimiento'],
                    });
                }
                localStorage.setItem('procedimientosAdministrativos', JSON.stringify(procedimientosList));
                resolve(procedimientosList);
            });
        });
    }

    async getDatosProcedimiento(procedimientoId: string | undefined): Promise<any> {
        const query = {
            appId: environment.appId,
            userToken: '',
            appKey: environment.appKey,
            procedimiento: environment.procedimiento,
            tipo_identificador: 'procedimiento.seda.signatura',
            identificador: procedimientoId,
            atributos: [
                'procedimiento.servicio.es_servicio',
                'procedimiento.signatura',
                'procedimiento.nombre',
                'procedimiento.tiene_organismo_productor.nombre_organismo_productor',
                'procedimiento.tiene_organismo_productor.codigo_organismo_productor',
            ],
        };
        return new Promise((resolve) => {
            this.http.post(`${urlDynamo}/api/data/v1/obtener_entidad`, query).subscribe((resp: any) => {
                resolve(resp.body.atributos?.procedimiento);
            });
        });
    }

    setProcedimientoActual(procedimiento: ProcedimientoAdministrativo) {
        localStorage.setItem('procedimientoAdhesion', JSON.stringify(procedimiento));
    }

    getProcedimientoActual(): ProcedimientoAdministrativo {
        return JSON.parse(localStorage.getItem('procedimientoAdhesion')!);
    }
}
